import { PharmacyTag } from '../SourcePage/types';
import { OrderStatusesType } from '../OrderPage/types';

export enum OrderStatus {
  UNCONFIRMED = 'unconfirmed',
  COLLECTING = 'collecting',
  READY = 'ready',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  CLARIFICATION = 'clarification',
}

export enum DeliveryType {
  DELIVERY = 'delivery',
  SELF = 'self',
}

export enum PaymentType {
  KASPI_PAY = 'kaspi_pay',
  IN_PLACE = 'in_place',
  INTERPAY = 'interpay',
}

export enum OrdersTabOptions {
  'selfOrders',
  'overdueOrders',
  'allOrders',
  'deliveryOrders',
  'newOrders',
  'yellowOrders',
  'networkOrders',
  'foodOrders',
  'interpayOrders',
}

export interface DeliveryOption {
  delivery_type: string;
  distance: number;
  eta: number;
  price: number;
  provider: string;
  slots?: DeliveryOption[];
  payment_status?: string;
  orderEta?: number;
  on_demand: string;
  type?: string;
  end: string;
  start: string;
  price_fact?: number;
  price_fact_calculated: boolean;
  claim_id?: string;
  claim_status?: string;
  claim_price?: number;
  refund_sum?: number;
}

export interface OrderData {
  anonymous: boolean;
  reissued_from?: string;
  reissued_to?: string;
  viewed: boolean;
  whatsapp_number?: string | null;
  payer?: {
    phone?: string | null;
    name?: string;
  } | null;
  payment_on_site: boolean;
  muted: boolean;
  cancel_message: string;
  is_test?: boolean;
  created_at: string;
  delivery_method: 'self' | 'delivery_yandex';
  delivery_price: number;
  order_number: string;
  payment_method: 'kaspi' | 'in_place';
  phone: string;
  paid: boolean;
  paid_delivery: boolean;
  was_paid_at: string;
  closed: boolean;
  received_in_soft: boolean;
  user_source: string;
  source: string;
  sourceAddress: string;
  city?: string;
  payment_status?: string;
  delivery_payment_status?: string;
  bonus_used?: boolean;
  promocode?: {
    code?: string;
    description?: string;
    discount_target?: string;
    discount_type?: string;
    type?: string;
    value?: number;
    max_user_orders?: number;
  };
  refund_sum?: number;
  total_sum_with_discount?: number;
  total_price_with_discount?: number;
  recipient: {
    phone: string;
    definition: string;
  };
  delivery_price_with_discount?: number;
  delivery: DeliveryOption;
  status:
    | string
    | {
        next: string[];
        status: string;
      };
  pharmacy_status:
    | string
    | {
        next: string[];
        status: string;
      };
  claim_status?: string;

  collector: {
    name: string;
    phone: string;
  };
  items: {
    base_price: number;
    min_customer_price: number;
    max_customer_price: number;
    avg_customer_price: number;
    name: string;
    part_id: number;
    price_with_warehouse_discount: number;
    quantity: number;
    pharmacy_count: number;
    sku: string;
    ware_id: string;
    source_code: string;
    warehouse_discount: number;
    total_price: number;
    proposal: {
      source_code: string;
      network_code: string;
      pharmacy_name: string;
      sku: string;
      name: string;
      base_price: number;
      price_with_warehouse_discount: number;
      warehouse_discount: number;
      agent_commission: number;
      quantity: number;
      ware_id: string;
      count: number;
      pharmacy_count: number;
      analog: string;
      total_price: number;
    };
  }[];
  user_address: number;
  comment: string;
  receipts: string[];
  total_price: number;
  address: {
    id: number;
    city: string;
    entrance: string;
    address: string;
    flat: number;
    latitude?: number;
    longitude?: number;
  };
  timer?: {
    comment: string;
    end: string;
    remaining: number;
    start: string;
    status: string;
    local_time: string;
    user_message: string;
    type: string;
  };
  pharmacy_timer?: {
    comment: string;
    end: string;
    remaining: number;
    start: string;
    status: string;
    local_time: string;
    type: string;
    user_message: string;
  };
}
export interface DeliveryOptionData {
  deliveryType: string;
  distance: number;
  eta: number;
  price: number;
  provider: string;
  slots?: DeliveryOptionData[];
  slotsAll?: DeliveryOptionData[];
  slotsCurrent?: DeliveryOptionData[];
}
export interface OrderSource {
  address: string;
  bin: string;
  code: string;
  lat: number;
  lon: number;
  name: string;
  network_code: string;
  opening_hours: string;
  with_reserve: boolean;
  soft: string;
  payment_on_site: boolean;
  source_tags: { tag_id: number; meta?: string }[];
  network_tags: { tag_id: number; meta?: string }[];
}

export interface UserData {
  addresses: null | string;
  default_address: null | string;
  name: null | string;
  is_collector: boolean;
  collecting_source_code: string | null;
  phone: string;
  role: {
    id: string;
    description: string | null;
  };
  values: {
    network_code: string;
    source_code: string;
  };
}

export interface OrderSourceData {
  address: string;
  bin: string;
  code: string;
  lat: number;
  lon: number;
  name: string;
  opening_hours: string;
}

export interface OrdersResponseParam {
  status: string;
  result: {
    order: OrderData;
    source: OrderSource;
  }[];
}

interface Network {
  id: number;
  name: string;
}

export interface AllNetworkEnabledParam {
  status: string;
  networks: Network[];
}
export interface OrderResponseParam {
  status: string;
  result: {
    order: OrderData;
    source: OrderSource;
  };
}

export interface StatusResponse {
  result: OrderStatusesType;
  status: string;
}

export interface TestOrdersRes {
  id: string;
  status: string;
}

export interface ImgResponseParam {
  status: string;
  result: {
    link: string;
    filename: string;
  };
}

export interface OrdersChangeStatusParams {
  status: string;
}

export interface createClaimParams {
  status: string;
}

export interface coordinatesParams {
  status: string;
}
export interface cancelClaimParams {
  status: string;
}

export interface ProductResponseItemCustomAttributesProps {
  attribute_code: string;
  value: string;
}

export interface ProductResponseItemCustomAttributesPropsCache {
  code: string;
  values: string;
}
export interface ProductResponseItem {
  attribute_set_id: number;
  created_at: string;
  id: string | number;
  name: string;
  price: number;
  sku: string;
  status: number;
  type_id: string;
  updated_at: string;
  visibility: number;
  custom_attributes: ProductResponseItemCustomAttributesProps[];
}

export interface ProductResponseItemCache {
  attribute_set_id: number;
  created_at: string;
  id: string | number;
  name: string;
  price: number;
  sku: string;
  status: number;
  type_id: string;
  updated_at: string;
  visibility: number;
  attributes: ProductResponseItemCustomAttributesPropsCache[];
}

export interface RefundItem {
  amount: 0;
  comment: string;
  created_at: string;
  payment_uuid: string;
  status: string;
  updated_at: string;
  uuid: string;
}

export interface PaymentResponseItemCache {
  label?: string;
  type?: string;
  amount: number;
  comment: string;
  created_at: string;
  num_in_order: number;
  order_id: string;
  paid_at: string;
  payment_method: string;
  payment_type: string;
  refund_type: string;
  payment_url: string;
  pharmacy_code: string;
  phone: string;
  refund_amount: number;
  refunds: RefundItem[];
  status: string;
  updated_at: string;
  uuid: string;
}

export interface PriceResponsePharmacy {
  agent_commission: number;
  base_price: number;
  pharmacy_name: string;
  price_with_warehouse_discount: number;
  quantity: number;
  sku: string;
  ware_id: string;
  warehouse_discount: number;
  min_customer_price: number;
  avg_customer_price: number;
  max_customer_price: number;
}

export interface ProductsResponse {
  items: ProductResponseItem[];
}

export interface ProductsResponseCache {
  result: ProductResponseItemCache[];
}

export interface PaymentsResponseCache {
  result: PaymentResponseItemCache[];
}

export interface OrderTableProductData {
  amount: string;
  sku: string;
  name: string;
  quantity: number;
  packing: string;
  manufacturer: string;
  needsRecipe: boolean;
  vendorCode: string;
  needsStrongRecipe?: boolean;
}

export interface AddressData {
  city: string;
  address: string;
  entrance: string;
  id: number;
  flat: number;
  floor: number;
  comment: any;
  name?: string;
  latitude?: number;
  longitude?: number;
  icon?: string;
}

export interface OrderTableData {
  id: string;
  reissued_from: string;
  reissued_to: string;
  viewed: boolean;
  muted: boolean;
  phone: string;
  paymentStatusDelivery?: string;
  paymentStatus?: string;
  test?: boolean;
  orderNumber: string;
  userCancelMessage: string;
  userSource: string;
  networkCode: string;
  sourceLat: string;
  sourceLon: string;
  createdAt: string;
  originalCreatedAt: string;
  recipient: {
    phone: string;
    definition: string;
  };
  wasPaidAt: string;
  whatsappNumber?: string | null;
  deliveryPaymentStatus?: string;
  payer?: {
    phone?: string | null;
    name?: string;
  } | null;
  diffMsTimer: number;
  amount: string;
  price: string;
  schedule?: string;
  paid: boolean;
  city?: string;
  paidDelivery: boolean;
  closed: boolean;
  receivedInSoft: boolean;
  deliveryPriceWithDiscount?: number;
  oldPrice: string;
  status: string;
  statusName: string;
  statusColor: string;
  statusDelivery: string;
  statusNameDelivery: string;
  statusColorDelivery: string;
  paymentOnSite: boolean;
  bonusUsed: boolean;
  promoCode?: {
    code?: string;
    description?: string;
    discount_target?: string;
    discount_type?: string;
    type?: string;
    value?: number;
    max_user_orders?: number;
  };
  deliveryMethod: string;
  statusPharmacy: string;
  statusNamePharmacy: string;
  statusColorPharmacy: string;
  statusNext: string[];
  statusForManger: string;
  paymentType: PaymentType;
  deliveryType: DeliveryType;
  source: string;
  sourceName?: string;
  sourceAddress?: string;
  soft: string;
  receipts?: string[] | null;
  products: OrderTableProductData[];
  address: AddressData;
  comment: string;
  recipe: string;
  sourceTags: PharmacyTag[] | null;
  networkTags: PharmacyTag[] | null;
  amountWithDiscount?: string;
  refundSum: number;
  deliveryPriceWithPromoCode?: number;
  deliveryPrice?: number;
  timer?: {
    commentTimer: string;
    endTimer: string;
    remainTimer: number;
    startTimer: string;
    statusTimer: string;
    localTime: string;
    userMessage: string;
  };
  pharmacyTimer?: {
    commentTimer: string;
    endTimer: string;
    remainTimer: number;
    startTimer: string;
    statusTimer: string;
    localTime: string;
  };

  delivery: {
    deliveryRefundSum: number;
    deliveryType: string;
    distance: number;
    eta: number;
    end: string;
    priceFact?: number;
    priceFactCalculated: boolean;
    price: number;
    provider: string;
    slots: DeliveryOptionData[];
    type?: string;
    orderEta?: number;
    claimId?: string;
    claimStatus?: string;
    claimPrice?: number;
    slotsAll?: DeliveryOptionData[];
    slotsCurrent?: DeliveryOptionData[];
  };

  collector: {
    name: string;
    phone: string;
  };

  payments: {
    order: PaymentResponseItemCache[];
    items: PaymentResponseItemCache[];
    delivery: PaymentResponseItemCache[];
  };
}

export interface SourceData {
  name: string;
  city: string;
  address: string;
  openingHours: string;
  networkName: string;
  networkCode: string;
  lastSync: string;
  disableUpdatedAt: string;
  code: string;
  activated: boolean;
  magnum: boolean;
}

export interface ScheduleData {
  name: string;
  phone: string;
  role: string;
  active: boolean;
}

export interface CartHistoryMappedResponse {
  created_at: string;
  current: string;
  current_description: string;
  event: string;
  eventType: string;
  item_id: string;
  phone: string;
  previous: string;
  previous_description: string;
  role: string;
}

export interface OrdersPageState {
  ordersPage: {
    data: null | OrderTableData[];
    error: null | string;
    loading: boolean;
  };

  allOrders: {
    data: null | OrderTableData[];
    error: null | string;
    loading: boolean;
  };

  allNetworkEnabled: {
    data: null | {
      [key: string]: {
        automation_state: string;
      };
    } | null;
    error: null | string;
    loading: boolean;
  };

  ordersSearch: {
    data: null | OrderTableData[];
    error: null | string;
    loading: boolean;
  };

  selectedOrder: {
    data: null | OrderTableData;
    error: null | string;
    loading: boolean;
  };

  sourcesData: {
    data: SourceData[] | null;
    loading: boolean;
    error: null | string;
  };
  scheduleData: {
    data: ScheduleData[] | null;
    loading: boolean;
    error: null | string;
  };

  notMuted: {
    data: {
      count: number;
      order_ids: string[];
    } | null;
    loading: boolean;
    error: null | string;
  };
  notViewed: {
    data: {
      count: number;
      order_ids: string[];
    } | null;
    loading: boolean;
    error: null | string;
  };

  createClaim: {
    data: {
      Result: { claim_id: string; claim_price: number; claim_status: string };
    } | null;
    loading: boolean;
    error: null | string;
  };

  coordinates: {
    data: {
      result: { lat: number; lon: number };
    } | null;
    loading: boolean;
    error: null | string;
  };

  orderId: string | null;

  historyModal: {
    data: CartHistoryMappedResponse[] | null;
    loading: boolean;
    error: null | string;
  };
}

export interface CartChangeItem {
  name: string;
  quantity: number;
}

export interface OrderHistoryEvent {
  created_at: string;
  current: string;
  event: string;
  event_type: string;
  has_problem: boolean;
  item_id: string;
  phone: string;
  role: string;
  previous: string;
  collector?: string;
}

export interface OrderHistoryResponse {
  result: {
    count_problems: number;
    items: OrderHistoryEvent[];
  };
  status: string;
}

export enum UserRole {
  OPERATOR = 'operator',
  MANAGER = 'manager',
  PHARMACIST = 'pharmacist',
  CUSTOMER = 'customer',
  SYSTEM = 'system',
  ADMIN = 'admin',
  CONTENT = 'content',
  PHARMACY_MANAGER = 'pharmacy_manager',
  HEAD_OPERATOR = 'head_operator',
}

export enum SoftType {
  STANDART = 'STANDART',
}
